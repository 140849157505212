import React from "react";
import TripExpensePage from "./../TripExpensePage/TripExpensePage";
import TripPurchaserPage from "./../TripPurchaserPage/TripPurchaserPage";
import GeneralExpensePage from "./../GeneralExpensePage/GeneralExpensePage";
import Button from "@material-ui/core/Button";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { BASE_URL ,  SEAANGEL } from "../../config";

import { useHistory, useParams } from "react-router-dom";

export default function TripView(props) {
  const [expenses, setExpenses] = React.useState([]);
  const [genExpenses, setGenExpenses] = React.useState([]);
  const [fish, setFish] = React.useState([]);
  const [purchasers, setPurchasers] = React.useState([]);
  const [trip, setTrip] = React.useState({});
  const [tripCash, setTripCash] = React.useState(0);

  const [genExTotal, setTotal] = React.useState(0);

  const [expenseTotal, setExpenseTotal] = React.useState(0);
  const [purchaserTotal, setPurchaserTotal] = React.useState(0);
  const [finalTotal, setFinalTotal] = React.useState(0);

  const [diesel, setDiesel] = React.useState({});
  const [kanni, setKanni] = React.useState({});
  const [kottu, setKottu] = React.useState({});
  const [writer, setWriter] = React.useState({});
  const [tandel, setTandel] = React.useState({});

  const { trip_id } = useParams();

  const [newID, setNewID] = React.useState(trip_id);


  const history = useHistory();

  const fetchNewID = (new_trip_id,status) => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/new_id/"+new_trip_id+"?status="+status, {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setNewID(res.id);
      });
  };

  React.useEffect(() => {
    fetchExpenses();
    fetchFish();
    fetchPurchasers();
    fetchTrip();
    fetchTripCash();
    fetchGenExpenses();
  }, [newID]);

  const fetchExpenses = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/expenses?all=1", {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setExpenses((res.items));
      });
  };

  const fetchGenExpenses = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/expenses?all=1&is_trip=0", {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setGenExpenses((res.items));
      });
  };

  const fetchTrip = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip/" + newID, {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setTrip(res);
      });
  };

  const fetchTripCash = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/trip_cash_total?trip_id=" + newID, {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setTripCash(res.amount);
      });
  };

  const fetchFish = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/fishs?all=1", {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setFish(JSON.parse(res.items));
      });
  };

  const fetchPurchasers = () => {
    let tk = window.localStorage.getItem("fish-token");
    fetch(BASE_URL + "/api/purchasers?all=1", {
      headers: {
        "x-access-token": tk,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setPurchasers((res.items));
      });
  };

  return (
    <div>
      <div style={{ textAlign: "center", marginTop: "30px" }}>
        <Button
        style={{margin: 10}}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => fetchNewID(newID,'prev')}
        >
          {"<<"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => fetchNewID(newID,'next')}
        >
         {">>"}
        </Button>
      </div>

      <TripPurchaserPage
        trip={trip}
        trip_id={newID}
        fetchFish={fetchFish}
        fetchPurchasers={fetchPurchasers}
        fish={fish}
        setPurchaserTotal={setPurchaserTotal}
        purchasers={purchasers}
        tripCash={tripCash}
        user={props.user}
        fetchTripCash={fetchTripCash}
      />


      <TripExpensePage
        setExpenseTotal={setExpenseTotal}
        setFinalTotal={setFinalTotal}
        trip_id={newID}
        fetchExpenses={fetchExpenses}
        expenses={expenses}
        genExpenses={genExpenses}
        user={props.user}
        setDiesel={setDiesel}
        setTandel={setTandel}
        setKanni={setKanni}
        setKottu={setKottu}
        setWriter={setWriter}
        kanni={kanni}
        kottu={kottu}
        writer={writer}
        tandel={tandel}
        diesel={diesel}
        purchaserTotal={purchaserTotal}
        expenseTotal={expenseTotal}
      />

      {!SEAANGEL && (
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className="p0" colSpan="4" align="center">
                  .
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="p0" colSpan="4" align="center">
                  <p className="m3 f20 b green">
                    {" "}
                    Pruchaser :{" "}
                    <span className="green"> ₹ {purchaserTotal}</span>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="p0" colSpan="4" align="center">
                  <p className="m3 f20 b red">
                    {" "}
                    Expense : <span className="red"> ₹ {expenseTotal}</span>
                  </p>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="p0" colSpan="4" align="center">
                  <p className="m3 f20 b">
                    {" "}
                    Profit : ₹ {purchaserTotal - expenseTotal}
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <GeneralExpensePage
        user={props.user}
        trip_id={newID}
        setTotal={setTotal}
      />
      {SEAANGEL ? (
        <TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b red"> General Expense : ₹ {genExTotal}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                .
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b green" > Profit : ₹ {finalTotal}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                .
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b" style={{fontSize: 25}} >
                  {" "}
                  Profit/Loss : ₹{" "}
                  {finalTotal -  genExTotal}
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      ) : (
<TableContainer>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b red"> General Expense : ₹ {genExTotal}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b red"> Trip Expense : ₹ {expenseTotal}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 b red b" style={{fontSize: 25}}> Total Expense : ₹ {genExTotal + expenseTotal}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                .
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b green" style={{fontSize: 25}} > Purchaser : ₹ {purchaserTotal}</p>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                .
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="p0" colSpan="4" align="center">
                <p className="m3 f20 b" style={{fontSize: 25}} >
                  {" "}
                  Profit/Loss : ₹{" "}
                  {purchaserTotal - expenseTotal - genExTotal - finalTotal}
                </p>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      )}
    
    <div style={{ textAlign: "center", marginTop: "30px" }}>
        <Button
        style={{margin: 10}}
          variant="contained"
          color="primary"
          size="large"
          onClick={() => fetchNewID(newID,'prev')}
        >
          {"<<"}
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => fetchNewID(newID,'next')}
        >
         {">>"}
        </Button>
      </div>
    </div>
  );
}
